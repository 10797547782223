$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    // create instance of the plugin, but hold on till needed
    var acc = $('.accHandler').accordion({onhold: true, openFirst: true});
    $win = $(window);

    // start the accordion when window is smaller than 902px
    $win.resize(function() {
        if (breakpoint['medium']) acc.destroy();
        else acc.init();
    });

    // trigger resize to check if accordion is needed
    $win.trigger('resize');

    $('.date-only-picker').datepicker({
        language: 'de',
        maxDate: new Date((new Date).getTime() - 1000*60*60*24*365),
        minDate: new Date((new Date).getTime() - 1000*60*60*24*365*100)
    });

    var coeff = 1000 * 60 * 5;
    var date = new Date();  //or use any other date
    var rounded = new Date(Math.ceil(date.getTime() / coeff) * coeff)

    if ($('.date-only-picker').length) {
        $('.date-only-picker').datepicker().data('datepicker').selectDate(rounded);
        $('.date-only-picker').val('');
    }

    // navigation-state and btt-state handler
   var $btt =      $('.backToTop'),
       $navi =     $('.page-navi'),
       navHeight = $navi.height(),
       headlineOffset = $("main").offset().top / 1.5,
       dockClass = 'is_reduced',
       offsetForDock = headlineOffset;

    function fixedNavbarHandler() {
        if ($(window).scrollTop() > offsetForDock - navHeight) {
            $navi.addClass(dockClass);
        } else {
            $navi.removeClass(dockClass);
        }
    };

    fixedNavbarHandler();
    $(window).on('scroll', fixedNavbarHandler);

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * REPLACE a[href^="tel"] BY SPAN ELEMENT IF NOT MOBILE DEVICE
     */
    if(!('ontouchstart' in window || navigator.maxTouchPoints)) {
        phonelink();
    }

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        maxWidth: $(window).width() * 0.8,
        maxHeight: $(window).height() * 0.8,
        shrinkFactor:1,
        type: 'image'
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC.
     */
    $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        type: 'iframe',
        maxWidth: $(window).width() * 0.65,
        maxHeight: $(window).width() * 0.65 * 0.5625,
        iframe: {
            width: $(window).width() * 0.65,
            height: $(window).width() * 0.65 * 0.5625,
        }
    });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
    if (breakpoint['medium']) {

    }

    /**
     * detect external links and add target=_blank and then add rel="noopener"
     */
    $('a:external').attr('target', '_blank');
    $('a[target="_blank"]:not(.phone)').each(function() {
        var str = 'noopener';
        var attr = (this.getAttribute('rel') === null ? str : this.getAttribute('rel').replace(str, '') + ' ' + str);
        this.setAttribute('rel', attr);
    });

});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/